// eslint-disable-next-line import/prefer-default-export
export const NAVIGATION = [
  {
    title: 'Статистика',
    address: '/statistics',
  },
  {
    title: 'Уведомления',
    address: '/notifications',
  },
  {
    title: 'Бизнес Центры',
    address: '/offices',
  },
  {
    title: 'Группы',
    address: '/groups',
  },
  {
    title: 'Пользователи',
    address: '/users',
  },
]
