import React from 'react';
import { DatePicker, ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';

import Icon from '../../../../UI/Icon';
import { getMomentEndOfDay } from '../../utils/index';
import './styles.scss';

export default props => {
  const { name, value, setValue } = props;
  const iconClasses = 'icon__suitcase icon__suitcase_place_date-picker';

  return (
    <ConfigProvider locale={ruRU}>
      <DatePicker
        className='custom__picker'
        name={name}
        value={value}
        format='DD.MM.YYYY'
        disabledDate={current => current && current > getMomentEndOfDay()}
        suffixIcon={<Icon name={iconClasses} />}
        onChange={setValue}
      />
    </ConfigProvider>
  );
};
