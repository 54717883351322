import React from 'react';
import { useSelector } from 'react-redux';

import LargeSpin from '../../UI/Spin/LargeSpin';
import ScrollTransition from '../../UI/animations/transition/ScrollTransition';
import factsSchemes from './factsSchemes';
import styles from './styles.module.scss';

const renderFact = fact => {
  const { id, type, value, badgeValue } = fact;
  const { title } = factsSchemes[type];

  return (
    <div className={styles.fact__wrapper} key={id}>
      <p className={styles.fact__title}>{title}</p>
      <div className={styles.fact__value__wrapper}>
        <span className={styles.fact__value}>{value}</span>
        {badgeValue && <span className={styles.fact__badge}>{badgeValue}</span>}
      </div>
    </div>
  );
};

export default () => {
  const { isLoading, facts } = useSelector(state => state.statistics);

  if (isLoading || !facts.length) return <LargeSpin />;

  return (
    <ScrollTransition loading paginationLoading={false}>
      <div className={styles.root}>
        <h2 className={styles.main__title}>Статистика бронирования рабочих мест</h2>
        <div className={styles.facts__list}>
          {!!facts.length && facts.map(renderFact)}
        </div>
      </div>
    </ScrollTransition>
  );
};
