import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GET_OFFICES_NEW_DESIGN } from '../../../actions/offices';
import ListLoadingSpin from '../../UI/Spin/ListLoadingSpin';
import InnerComponent from './InnerComponent/InnerComponent';

export default () => {
  const { loading, list: offices } = useSelector(({ offices_newDesign }) => offices_newDesign);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_OFFICES_NEW_DESIGN });
  }, []);

  if (loading || !offices.length) return <ListLoadingSpin />;

  return <InnerComponent offices={offices} />;
};
