/* eslint-disable no-console */
import { call, put, takeLatest, takeEvery, select, all } from 'redux-saga/effects'
import { message } from 'antd'

import getResponse from './getResponse'
import nextPaginationStart from '../utils/nextPaginationStart'
import { setDefaultCursorStyle } from '../utils/cursorStyleSwitch'
import isNoMessage from '../utils/isNoMessage'
import HOST from '../utils/data/connectionData'
import { auth, content } from '../utils/data/requestHeaders'
import { PUT, POST, GET, DELETE } from '../utils/methods'
import { ITEMS_ON_PAGE } from '../utils/data/constants'
import { setRefreshRoomsLoading } from '../actions/actionCreator/offices'
import {
  ADD_IMAGE_TO_NEW_OFFICE,
  SET_PHOTO_LOADING,
  SET_STOP_PHOTO_LOADING,
  ADD_IMAGE_TO_NEW_ROOM,
} from '../actions/files'
import {
  DELETE_OFFICE,
  DELETE_OFFICE_SUCCEED,
  GET_FLOORS,
  DELETE_TABLE,
  DELETE_TABLE_SUCCEED,
  DELETE_TABLE_NEW_DESIGN,
  DELETE_ROOM,
  DELETE_ROOM_SUCCEED,
  DELETE_ROOM_NEW_DESIGN,
  GET_FLOORS_SUCCEED,
  GET_OFFICE,
  GET_OFFICE_FAILED,
  GET_OFFICE_FAILED_NEW_DESIGN,
  GET_OFFICE_SUCCEED,
  GET_OFFICE_SUCCEED_NEW_DESIGN,
  GET_OFFICES,
  GET_OFFICES_NEW_DESIGN,
  GET_OFFICES_FAILED,
  GET_OFFICES_FAILED_NEW_DESIGN,
  GET_OFFICES_SUCCEED,
  GET_OFFICES_SUCCEED_NEW_DESIGN,
  GET_ROOMS_LIST,
  GET_ROOMS_LIST_SUCCEED,
  GET_TABLE_TAGS,
  GET_TABLE_TAGS_SUCCEED,
  GET_TABLES,
  GET_TABLES_NEW_DESIGN,
  GET_TABLES_SUCCEED,
  GET_TABLES_SUCCEED_NEW_DESIGN,
  OFFICES_LOADING,
  OFFICES_LOADING_NEW_DESIGN,
  OFFICE_LOADING_NEW_DESIGN,
  POST_OFFICE,
  POST_OFFICE_SUCCEED,
  POST_ROOM,
  POST_ROOM_SUCCEED,
  POST_ROOM_NEW_DESIGN,
  POST_TABLE,
  POST_TABLE_NEW_DESIGN,
  PUT_TABLE,
  PUT_TABLE_SUCCEED,
  PUT_TABLE_NEW_DESIGN,
  PUT_OFFICE,
  PUT_OFFICE_SUCCEED,
  SET_FLOORS_LOADING,
  SET_ROOMS_LOADING,
  SET_TABLES_LOADING,
  SET_TABLES_LOADING_NEW_DESIGN,
  PUT_ROOM,
  PUT_ROOM_SUCCEED,
  ACTIVATE_TABLE,
  ACTIVATE_TABLE_SUCCEED,
  GET_SEARCH_OFFICES,
  GET_SEARCH_OFFICES_SUCCEED,
  SET_TABLE_TAGS_LOADING,
  DELETE_TABLE_TAG,
  DELETE_TABLE_TAG_SUCCEED,
  POST_ZONE,
  POST_ZONE_SUCCEED,
  PUT_ZONE,
  PUT_ZONE_SUCCEED,
  DELETE_ZONE,
  DELETE_ZONE_SUCCEED,
  POST_MAP_TO_FLOOR,
  ADD_MARKER,
  ADD_MARKER_SUCCEED,
  POST_MAP_TO_FLOOR_SUCCEED,
  DELETE_MAP_SUCCEED,
  DELETE_MAP,
  DELETE_MARKER,
  DELETE_MARKER_SUCCEED,
  DELETE_MARKERS_ON_FLOOR,
  DELETE_MARKERS_ON_FLOOR_SUCCEED,
  POST_OFFICE_IMAGE,
  POST_OFFICE_IMAGE_SUCCEED,
  POST_ROOM_IMAGE,
  POST_ROOM_IMAGE_SUCCEED,
  GET_LICENSES,
  GET_LICENSES_SUCCEED,
  POST_FLOOR,
  POST_FLOOR_NEW_DESIGN_SUCCEED,
  PUT_FLOOR,
  PUT_FLOOR_NEW_DESIGN_SUCCEED,
  DELETE_FLOOR,
  DELETE_FLOOR_SUCCEED_NEW_DESIGN,
  GET_OFFICE_NEW_DESIGN,
  GET_OFFICE_LOCAL,
  POST_OFFICE_NEW_DESIGN,
  PUT_OFFICE_NEW_DESIGN,
  PUT_OFFICE_SUCCEED_NEW_DESIGN,
  POST_OFFICE_SUCCEED_NEW_DESIGN,
  POST_MAP_TO_FLOOR_SUCCEED_NEW_DESIGN,
  POST_MAP_TO_FLOOR_NEW_DESIGN,
  GET_OFFICE_TAGS,
  SET_OFFICE_TAGS_LOADING,
  GET_OFFICE_TAGS_FAILED,
  GET_OFFICE_TAGS_SUCCEED,
  POST_TAG_ICON_NEW_DESIGN,
  DELETE_OFFICE_TAG_SUCCEED,
  DELETE_OFFICE_TAG,
  POST_OFFICE_TAG_SUCCEED,
  POST_OFFICE_TAG,
  PUT_OFFICE_TAG,
  PUT_OFFICE_TAG_SUCCEED,
  PUT_ROOM_NEW_DESIGN,
  DELETE_ROOMS_NEW_DESIGN,
  DELETE_TABLES_NEW_DESIGN,
  POST_OFFICE_IMAGES_NEW_DESIGN,
  POST_OFFICE_IMAGES_SUCCEED_NEW_DESIGN,
  POST_ROOM_TYPE_SUCCEED,
  POST_ROOM_TYPE,
  PUT_ROOM_TYPE_SUCCEED,
  PUT_ROOM_TYPE,
  DELETE_ROOM_TYPE_SUCCEED,
  DELETE_ROOM_TYPE,
  GET_ROOM_TYPES,
  GET_ROOM_TYPES_SUCCEED,
  GET_ROOM_TYPES_FAILED,
  SET_ROOM_TYPES_LOADING,
  POST_TAG_ICON_SUCCEED_NEW_DESIGN,
  POST_ROOM_TYPE_ICON,
  POST_ROOM_TYPE_ICON_SUCCEED,
  GET_FILTERED_ROOMS,
  DELETE_ROOM_FROM_MAP,
  DELETE_ROOM_FROM_MAP_SUCCEED,
  REFRESH_ROOMS,
  REFRESH_ROOMS_SUCCESS,
  UPDATE_ADVANCED_SETTINS_SUCCESS,
  UPDATE_ADVANCED_SETTINS_RESPONSE,
  UPDATE_ADVANCED_SETTINS_FAILED,
} from '../actions/offices'
import formPath, { createPathWithFilters } from '../utils/formPath'

// <------ Floors ------>

function* getFloors({ officeId, filter, expanded }) {
  try {
    yield put({ type: SET_FLOORS_LOADING })
    const path = formPath('/floor', [
      { office: officeId },
      { expand: expanded ? 1 : 0 },
      { type: filter }])

    const floors = yield getResponse({ method: GET, path: path, headers: auth })

    yield put({ type: GET_FLOORS_SUCCEED, floors })
    return floors
  } catch (e) { console.log(e.message) }
}

function* postFloor({ floor, callback, isPackageUpload }) {
  try {
    const response = yield getResponse({
      method: POST,
      path: '/floor',
      headers: content,
      body: floor,
    })

    if (!isNoMessage(response)) return

    yield put({
      type: POST_FLOOR_NEW_DESIGN_SUCCEED,
      payload: isPackageUpload && Array.isArray(response) ? response : [response],
    })

    message.success(Array.isArray(response)
      ? `Этажи ${response.map(floor => floor.title).join(', ')} добавлены`
      : `Этаж ${response.title} добавлен`)
    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* putFloor({ floorId, floor, callback }) {
  try {
    const newFloor = yield getResponse({
      method: PUT,
      path: `/floor/${floorId}`,
      headers: content,
      body: floor,
    })

    if (!isNoMessage(newFloor)) return
    yield put({ type: PUT_FLOOR_NEW_DESIGN_SUCCEED, newFloor })
    message.success('Изменения сохранены')
    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* deleteFloor({ floorId, callback }) {
  try {
    const response = yield getResponse({
      method: DELETE,
      path: `/floor/${floorId}`,
      headers: auth,
    })

    if (!isNoMessage(response)) return

    yield put({ type: DELETE_FLOOR_SUCCEED_NEW_DESIGN, floorId })
    yield callback && callback()
    message.success('Этаж удален')
  } catch (e) { console.log(e.message) }
}

// <------ Room Types management ------>

function* getRoomTypes({ officeId }) {
  try {
    yield put({ type: SET_ROOM_TYPES_LOADING })
    const response = yield getResponse({
      method: GET,
      path: `/room/type?office=${officeId}`,
      headers: auth,
    })

    yield put({
      type: GET_ROOM_TYPES_SUCCEED,
      list: isNoMessage(response) ? response : [],
    })
    return response
  } catch (e) {
    yield put({ type: GET_ROOM_TYPES_FAILED, list: [] })
    console.log(e.message)
  }
}

function* postRoomType({ payload, callback, isPackageUpload }) {
  try {
    const response = yield getResponse({
      method: POST,
      path: '/room/type',
      body: payload,
      headers: content,
    })

    if (!isNoMessage(response) && !Array.isArray(response)) return

    yield put({
      type: POST_ROOM_TYPE_SUCCEED,
      payload: Array.isArray(response) ? response : [response],
    })
    message.success('Сохранено')
    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* putRoomType({ roomTypeId, payload, callback }) {
  try {
    const response = yield getResponse({
      method: PUT,
      path: `/room/type/${roomTypeId}`,
      body: payload,
      headers: content,
    })
    if (!isNoMessage(response)) return

    yield put({ type: PUT_ROOM_TYPE_SUCCEED, roomType: response })
    message.success('Изменения сохранены')
    yield callback()
  } catch (e) { console.log(e.message) }
}

function* deleteRoomType({ roomTypeId }) {
  try {
    const response = yield getResponse({
      method: DELETE,
      path: `/room/type/${roomTypeId}`,
      headers: auth,
    })
    if (!isNoMessage(response)) return

    yield put({ type: DELETE_ROOM_TYPE_SUCCEED, roomTypeId })
  } catch (e) { console.log(e.message) }
}

function* postRoomTypeIcon({ roomTypeId, icon }) {
  try {
    const postedImage = yield getResponse({
      method: POST,
      path: '/files',
      headers: auth,
      body: icon,
      isFile: true,
    })
    if (!isNoMessage(postedImage)) return

    const roomTypeWithIcon = { tag: roomTypeId, icon: postedImage.id }

    const updatedRoomType = yield getResponse({
      method: PUT,
      path: `/room/type/${roomTypeId}`,
      headers: content,
      body: roomTypeWithIcon,
    })
    if (!isNoMessage(updatedRoomType)) return

    yield put({ type: POST_ROOM_TYPE_ICON_SUCCEED, roomType: updatedRoomType })
  } catch (e) { console.log(e.message) }
}

// <------ Offices ----->

function* getOfficesList({ page = 1, callback, filter, searchString, all }) {
  try {
    yield put({ type: OFFICES_LOADING })
    const path = all ? '/office' : formPath('/office', [
      { start: nextPaginationStart(page) },
      { limit: ITEMS_ON_PAGE },
      { search: searchString },
      { tags: filter?.length ? filter.join('&tags=') : '' }])

    const offices = yield getResponse({ method: GET, path: path, headers: auth })

    yield put({ type: GET_OFFICES_SUCCEED, list: offices.results, count: offices.count, page })
    callback && callback()
  } catch (e) {
    yield put({ type: GET_OFFICES_FAILED })
    console.log(e.message)
  }
}

function* getOfficesListNewDesign({ page = 1, callback, filter, searchString, all }) {
  try {
    yield put({ type: OFFICES_LOADING_NEW_DESIGN })
    const path = all ? '/office' : formPath('/office', [
      { start: nextPaginationStart(page) },
      { limit: ITEMS_ON_PAGE },
      { search: searchString },
      { tags: filter?.length ? filter.join('&tags=') : '' }])

    const offices = yield getResponse({ method: GET, path: path, headers: auth })

    yield put({
      type: GET_OFFICES_SUCCEED_NEW_DESIGN,
      list: offices.results,
      count: offices.count,
      page,
    })
    yield callback && callback()
  } catch (e) {
    yield put({ type: GET_OFFICES_FAILED_NEW_DESIGN })
    console.log(e.message)
  }
}

function* getOfficesListSearch({ search }) {
  try {
    const path = `/office?search=${search}`
    const offices = yield getResponse({ method: GET, path: path, headers: auth })

    yield put({ type: GET_SEARCH_OFFICES_SUCCEED, list: offices.results })
  } catch (e) { console.log(e.message) }
}

const getRoomsWithPhoto = rooms => rooms.map(room => {
  const newRoom = room
  newRoom.photo = room?.images?.length > 0 ? room?.images[0] : {}
  return newRoom
})

function* getOffice({ officeId, editMode, callback, filter, expanded }) {
  try {
    if (officeId === null) return null
    yield put({ type: OFFICES_LOADING })
    const office = yield getResponse({
      method: GET,
      path: `/office?id=${officeId}`,
      headers: auth,
    })

    if (!isNoMessage(office)) return

    const floors = yield call(() => getFloors({ officeId, filter: filter || null, expanded }))
    if (!isNoMessage(floors)) return

    const rooms = yield floors.reduce((accumulator, next) => next.rooms.length > 0
      ? [...accumulator, ...next.rooms]
      : accumulator, [])

    const photo = office?.images.length > 0 ? office?.images[0] : {}

    office.photo = photo
    office.rooms = getRoomsWithPhoto(rooms)

    yield put({ type: GET_OFFICE_SUCCEED, office, editMode })
    yield callback && callback()
  } catch (e) {
    yield put({ type: GET_OFFICE_FAILED })
    console.log(e.message)
  }
}

function* getOfficeNewDesign({ officeId, editMode, callback, filter, expanded }) {
  try {
    if (officeId === null) return null
    yield put({ type: OFFICE_LOADING_NEW_DESIGN })

    const office = yield getResponse({ method: GET, path: `/office?id=${officeId}`, headers: auth })

    if (!isNoMessage(office)) throw Error('Office loading error')

    const floors = yield call(() => getFloors({ officeId, filter: filter || null, expanded }))
    if (!isNoMessage(floors)) throw Error('Floors loading error')

    const rooms = yield floors.reduce((accumulator, next) => [...accumulator, ...next.rooms], [])
    office.rooms = rooms
    office.floors = floors
    // TODO-OL исправить, когда подключать к API буду
    office.advancedSettings = {
      time_before_book: 60,
      time_after_book: 60,
    }
    const advancedSettingsCamelCase = {
      timeBeforeBook: office.advancedSettings.time_before_book,
      timeAfterBook: office.advancedSettings.time_after_book,
    }
    office.advancedSettings = advancedSettingsCamelCase

    yield put({ type: GET_OFFICE_SUCCEED_NEW_DESIGN, office, editMode })
    yield callback && callback()
  } catch (e) {
    yield put({ type: GET_OFFICE_FAILED_NEW_DESIGN })
    console.log(e.message)
  }
}

function* getRoomsForLocalReducer({ filters = {}, officeId, callback }) {
  try {
    const filtersArr = Object.entries(filters)

    const path = filtersArr.length
      ? createPathWithFilters('/room', filtersArr)
      : `/room?office=${officeId}`

    const response = yield getResponse({
      method: GET,
      path,
      headers: auth,
    })
    if (!isNoMessage(response)) throw Error('Rooms loading error')

    callback && callback(response.results)
    return response.results
  } catch (e) {
    console.log(e.message)
    message.error('Произошла ошибка')
  }
}

function* getOfficeForLocalReducer({ officeId, callback }) {
  try {
    if (officeId === null) return null
    const office = yield getResponse({ method: GET, path: `/office?id=${officeId}`, headers: auth })
    if (!isNoMessage(office)) throw Error('Office loading error')

    const rooms = yield call(() => getRoomsForLocalReducer({ officeId }))
    if (!isNoMessage(rooms)) throw Error('Rooms loading error')

    const roomTypes = yield call(() => getRoomTypes({ officeId }))
    if (!isNoMessage(roomTypes)) throw Error('Room Types loading error')

    office.rooms = rooms
    office.roomTypes = roomTypes

    yield callback(office)
  } catch (e) {
    message.error('Произошла ошибка')
    yield callback()
    console.log(e.message)
  }
}

function* postOffice({ office, callback, onSuccess }) {
  try {
    const newOffice = yield getResponse({
      method: POST,
      path: '/office',
      headers: content,
      body: office,
    })

    if (isNoMessage(newOffice)) {
      const { files } = yield select()
      const officeWithPhoto = { ...newOffice, photo: files.currentFiles }

      yield put({ type: POST_OFFICE_SUCCEED, newOffice: officeWithPhoto })
      yield callback && callback()
      yield onSuccess && onSuccess()
      message.success('Бизнес-центр сохранен')
    }
    setDefaultCursorStyle()
  } catch (e) {
    setDefaultCursorStyle()
    console.log(e.message)
  }
}

function* postOfficeNewDesign({ office, callback }) {
  try {
    const newOffice = yield getResponse({
      method: POST,
      path: '/office',
      headers: content,
      body: office,
    })

    if (!isNoMessage(newOffice)) return
    yield put({ type: POST_OFFICE_SUCCEED_NEW_DESIGN, newOffice })
    yield callback && callback(newOffice.id)
    message.success('Бизнес-центр сохранен')
  } catch (e) { console.log(e.message) }
}

function* putOffice({ officeId, office, callback, onSuccess }) {
  try {
    const newOffice = yield getResponse({
      method: PUT,
      path: `/offices/${officeId}`,
      headers: content,
      body: office,
    })

    if (isNoMessage(newOffice)) {
      yield callback && callback()
      yield onSuccess && onSuccess()
      message.success('Изменения сохранены')
      yield put({ type: PUT_OFFICE_SUCCEED, newOffice })
    }
    setDefaultCursorStyle()
  } catch (e) {
    setDefaultCursorStyle()
    console.log(e.message)
  }
}

function* putOfficeNewDesign({ officeId, office }) {
  try {
    const updatedOffice = yield getResponse({
      method: PUT,
      path: `/offices/${officeId}`,
      headers: content,
      body: office,
    })

    if (!isNoMessage(updatedOffice)) return
    message.success('Изменения сохранены')
    yield put({ type: PUT_OFFICE_SUCCEED_NEW_DESIGN, updatedOffice })
  } catch (e) { console.log(e.message) }
}

function* putAdvancesSettings({ settings }) {
  try {
    yield put({ type: SET_OFFICE_TAGS_LOADING })
    const officeId = yield select(state => state.offices_newDesign.office.id)
    // TODO-OL - раскомментировать, когда будет API
    // const updatedAdvancesSettings = yield getResponse({
    //   method: PUT,
    //   path: `/advancesSettings`,
    //   headers: content,
    //   body: {
    //     officeId,
    //     settings,
    //   },
    // })

    // TODO-OL - удалить, когда будет API
    const updatedAdvancesSettings = yield new Promise(resolve => {
      setTimeout(() => resolve({
        time_before_book: settings.timeBeforeBook,
        time_after_book: settings.timeAfterBook,
      }), 3000)
    })

    const newSettings = {
      timeBeforeBook: updatedAdvancesSettings.time_before_book,
      timeAfterBook: updatedAdvancesSettings.time_after_book,
    }

    if (isNoMessage(updatedAdvancesSettings)) {
      yield put({ type: UPDATE_ADVANCED_SETTINS_SUCCESS, newSettings })
      message.success('Изменения сохранены')
    } else {
      yield put({ type: UPDATE_ADVANCED_SETTINS_FAILED })
      message.error('Произошла ошибка')
    }
  } catch (e) {
    yield put({ type: UPDATE_ADVANCED_SETTINS_FAILED })
    message.error('Произошла ошибка')
    console.log(e.message)
  }
}

function* loadOfficeImage({ image, isNew, callback }) {
  try {
    const newImage = yield getResponse({
      method: POST,
      path: '/files',
      headers: auth,
      body: image,
      isFile: true,
    })

    if (!isNoMessage(newImage)) return

    isNew
      ? yield put({ type: ADD_IMAGE_TO_NEW_OFFICE, newImage })
      : yield put({ type: POST_OFFICE_IMAGE_SUCCEED, newImage })

    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* postOfficeImagesNewDesign({ images, callback }) {
  try {
    const postedImages = yield all(images.map(image => getResponse({
      method: POST,
      path: '/files',
      headers: auth,
      body: image,
      isFile: true,
    })))

    if (postedImages.some(response => !isNoMessage(response))) return

    yield put({ type: POST_OFFICE_IMAGES_SUCCEED_NEW_DESIGN, postedImages })
    yield callback && callback(postedImages)
  } catch (e) { console.log(e.message) }
}

function* deleteOffice(action) {
  try {
    const { officeId, callback, title } = action
    const response = yield call(fetch, `${HOST}/offices/${officeId}`, {
      method: DELETE,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        'X-Workspace': process.env.REACT_APP_WORKSPACE,
      },
    })

    if (isNoMessage(response)) {
      yield put({ type: DELETE_OFFICE_SUCCEED, officeId })
      message.success(`Вы удалили БЦ "${title}"`)
      yield callback && callback()
    }
  } catch (e) {
    console.log(e.message)
    message.error(`Не удалось удалить "${action.title}"`)
  }
}

// <------ Rooms ------>

function* getRoomsList({ floorId, page, filter }) {
  try {
    yield put({ type: SET_ROOMS_LOADING })
    const startPosition = nextPaginationStart(page)
    const path = `/room?floor=${floorId}&start=${startPosition}&limit=${ITEMS_ON_PAGE}&expand=0`

    const floor = yield getResponse({
      method: GET,
      path: `${path}${filter ? `&type=${filter}` : ''}`,
      headers: auth,
    })

    if (isNoMessage(floor))
      yield put({ type: GET_ROOMS_LIST_SUCCEED, list: floor.results, count: floor.count, page })
  } catch (e) { console.log(e.message) }
}

function* refreshRooms({ withLoader = true }) {
  try {
    if (withLoader) yield put(setRefreshRoomsLoading(true))
    // yield put(setRefreshRoomsLoading(true))
    const officeId = yield select(state => state.offices_newDesign.office.id)
    const floors = yield call(() => getFloors({ officeId, filter: null, expanded: 1 }))
    if (!isNoMessage(floors)) throw Error('Floors loading error')
    const rooms = floors.reduce((accumulator, next) => [...accumulator, ...next.rooms], [])
    yield put({ type: REFRESH_ROOMS_SUCCESS, rooms })
    yield put(setRefreshRoomsLoading(false))
  } catch (e) {
    yield put(setRefreshRoomsLoading(false))
    console.log(e.message)
  }
}

function* postRoom({ room, callback }) {
  try {
    const roomWithoutFloorId = { ...room }
    const newRoom = yield getResponse({
      method: POST,
      path: '/room',
      headers: content,
      body: roomWithoutFloorId,
    })

    if (isNoMessage(newRoom)) {
      newRoom.photo = newRoom?.images?.length > 0 ? newRoom?.images[0] : {}
      yield put({ type: POST_ROOM_SUCCEED, newRoom: { ...newRoom } })

      yield callback && callback()
      message.success('Помещение добавлено')
    }
    setDefaultCursorStyle()
  } catch (e) {
    setDefaultCursorStyle()
    console.log(e.message)
  }
}

function* postRoomNewDesign({ room, callback }) {
  try {
    const newRoom = yield getResponse({
      method: POST,
      path: '/room',
      headers: content,
      body: room,
    })

    if (isNoMessage(newRoom)) {
      yield callback && callback(newRoom)
      yield put({ type: REFRESH_ROOMS })
      message.success('Помещение добавлено')
    }
  } catch (e) {
    message.error('Произошла ошибка')
    console.log(e.message)
  }
}

function* putRoom({ room, roomId, callback }) {
  try {
    const newRoom = yield getResponse({
      method: PUT,
      path: `/rooms/${roomId}`,
      headers: content,
      body: room,
    })

    if (isNoMessage(newRoom)) {
      newRoom.photo = newRoom?.images?.length > 0 ? newRoom?.images[0] : {}
      yield put({ type: PUT_ROOM_SUCCEED, newRoom })

      yield callback && callback()
      message.success('Изменения сохранены')
    }
    setDefaultCursorStyle()
  } catch (e) {
    setDefaultCursorStyle()
    console.log(e.message)
    yield callback && callback()
  }
}

function* putRoomNewDesign({ room, roomId, callback }) {
  try {
    const newRoom = yield getResponse({
      method: PUT,
      path: `/rooms/${roomId}`,
      headers: content,
      body: room,
    })

    if (isNoMessage(newRoom)) {
      yield callback && callback(newRoom)
      message.success('Изменения сохранены')
    }
  } catch (e) {
    message.error('Произошла ошибка')
    console.log(e.message)
  }
}

function* deleteRoom({ roomId, callback }) {
  try {
    const response = yield call(fetch, `${HOST}/rooms/${roomId}`, {
      method: DELETE,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        'X-Workspace': process.env.REACT_APP_WORKSPACE,
      },
    })

    if (isNoMessage(response)) {
      yield put({ type: DELETE_ROOM_SUCCEED, roomId })
      yield callback && callback()
      message.success('Помещение удалено')
    }
  } catch (e) { console.log(e.message) }
}

function* deleteRoomFromMap({ roomId }) {
  try {
    const response = yield call(fetch, `${HOST}/rooms/${roomId}`, {
      method: DELETE,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        'X-Workspace': process.env.REACT_APP_WORKSPACE,
      },
    })

    if (isNoMessage(response)) {
      yield put({ type: DELETE_ROOM_FROM_MAP_SUCCEED, roomId })
      message.success('Помещение удалено')
    }
  } catch (e) { console.log(e.message) }
}

function* deleteRoomNewDesign({ roomId, callback }) {
  try {
    const response = yield getResponse({
      method: DELETE,
      path: `/rooms/${roomId}`,
      headers: auth,
    })

    if (isNoMessage(response)) {
      yield callback && callback()
      message.success('Помещение удалено')
    }
  } catch (e) {
    console.log(e.message)
    message.error('Произошла ошибка')
  }
}

function* deleteRoomsNewDesign({ roomIds, callback }) {
  try {
    const getQuery = () =>
      roomIds.join('&id=')

    const response = yield getResponse({
      method: DELETE,
      path: `/room?id=${getQuery()}`,
      headers: auth,
    })

    if (isNoMessage(response)) {
      yield callback && callback()
      message.success('Помещения удалены')
    }
  } catch (e) {
    console.log(e.message)
    message.error('Произошла ошибка')
  }
}

function* loadRoomImage({ isNew, roomId, image, callback }) {
  try {
    const newImage = yield getResponse({
      method: POST,
      path: '/files',
      headers: auth,
      body: image,
      isFile: true,
    })

    if (!isNoMessage(newImage)) return

    isNew
      ? yield put({ type: ADD_IMAGE_TO_NEW_ROOM, newImage })
      : yield put({ type: POST_ROOM_IMAGE_SUCCEED, newImage, roomId })

    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

// <------ Tables ------>

function* activateTable({ tableId, roomId }) {
  try {
    const table = yield getResponse({
      method: POST,
      path: '/table/activate',
      headers: content,
      body: { table: tableId },
    })

    yield put({ type: ACTIVATE_TABLE_SUCCEED, table, roomId })
  } catch (e) { console.log(e.message) }
}

export function* getTables({ officeId, roomId, page, filter, editMode, callback }) {
  try {
    yield put({ type: SET_TABLES_LOADING })

    let path = `/table?office=${officeId}&room=${roomId}`
    if (page) path = `${path}&start=${nextPaginationStart(page)}&limit=${ITEMS_ON_PAGE}`
    if (filter) path = `${path}&free=${filter}`

    const tables = yield getResponse({ method: GET, path: path, headers: auth })
    yield put({
      roomId,
      page,
      editMode,
      count: tables.count,
      list: tables.results,
      type: GET_TABLES_SUCCEED,
    })

    yield callback && callback(tables.results)
    return tables.results
  } catch (e) { console.log(e.message) }
}

export function* getTablesNewDesign({ roomId }) {
  try {
    yield put({ type: SET_TABLES_LOADING_NEW_DESIGN, roomId })
    const path = `/table?room=${roomId}`

    const tables = yield getResponse({ method: GET, path: path, headers: auth })
    yield put({
      roomId,
      count: tables.count,
      list: tables.results,
      type: GET_TABLES_SUCCEED_NEW_DESIGN,
    })
    return tables.results
  } catch (e) { console.log(e.message) }
}

function* postTable({ table, callback }) {
  try {
    const newTable = yield getResponse({
      method: POST,
      path: '/table',
      headers: content,
      body: table,
    })

    if (isNoMessage(newTable)) {
      // yield put({ type: POST_TABLE_SUCCEED, newTable: { ...newTable, room: table.room } })
      yield callback && callback(newTable)
      message.success('Стол добавлен')
    }
  } catch (e) { console.log(e.message) }
}

function* postTableNewDesign({ table, callback }) {
  try {
    const newTable = yield getResponse({
      method: POST,
      path: '/table',
      headers: content,
      body: table,
    })

    if (isNoMessage(newTable)) {
      yield callback && callback(newTable)
      message.success('Стол добавлен')
    }
  } catch (e) {
    message.error('Произошла ошибка')
    console.log(e.message)
  }
}

function* putTable({ table, tableId, roomId, callback }) {
  try {
    const newTable = yield getResponse({
      method: PUT,
      path: `/tables/${tableId}`,
      headers: content,
      body: table,
    })

    if (isNoMessage(newTable)) {
      yield put({ type: PUT_TABLE_SUCCEED, newTable, roomId })
      yield callback && callback()
      message.success('Изменения сохранены')
    }
    setDefaultCursorStyle()
  } catch (e) {
    setDefaultCursorStyle()
    console.log(e.message)
  }
}

function* putTableNewDesign({ table, tableId, callback }) {
  try {
    const newTable = yield getResponse({
      method: PUT,
      path: `/tables/${tableId}`,
      headers: content,
      body: table,
    })

    if (isNoMessage(newTable)) {
      yield callback && callback(newTable)
      message.success('Изменения сохранены')
    }
  } catch (e) {
    message.success('Произошла ошибка')
    console.log(e.message)
  }
}

function* deleteTable({ tableId, roomId }) {
  try {
    const response = yield call(fetch, `${HOST}/tables/${tableId}`, {
      method: DELETE,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        'X-Workspace': process.env.REACT_APP_WORKSPACE,
      },
    })
    if (isNoMessage(response)) {
      yield put({ type: DELETE_TABLE_SUCCEED, tableId, roomId })
      message.success('Стол удален')
    }
  } catch (e) { console.log(e.message) }
}

function* deleteTableNewDesign({ tableId, callback }) {
  try {
    const response = yield getResponse({
      method: DELETE,
      path: `/tables/${tableId}`,
      headers: auth,
    })
    if (isNoMessage(response)) {
      yield callback && callback()
      message.success('Стол удален')
    }
  } catch (e) {
    message.success('Произошла ошибка')
    console.log(e.message)
  }
}

function* deleteTablesNewDesign({ tableIds, callback }) {
  try {
    const getQuery = () =>
      tableIds.join('&id=')

    const response = yield getResponse({
      method: DELETE,
      path: `/table?id=${getQuery()}`,
      headers: auth,
    })
    if (isNoMessage(response)) {
      yield callback && callback()
      message.success('Столы удалены')
    }
  } catch (e) {
    message.error('Произошла ошибка')
    console.log(e.message)
  }
}

function* getTableTags() {
  try {
    yield put({ type: SET_TABLE_TAGS_LOADING })
    const tagsList = yield getResponse({ method: GET, path: '/table_tag', headers: auth })
    yield put({ type: GET_TABLE_TAGS_SUCCEED, tagsList: tagsList || [] })
  } catch (e) { console.log(e.message) }
}

function* deleteTableTag({ tagId, callback }) {
  try {
    const response = yield getResponse({
      method: DELETE,
      path: `/table_tags/${tagId}`,
      headers: auth,
    })

    if (isNoMessage(response)) {
      yield put({ type: DELETE_TABLE_TAG_SUCCEED, tagId })
      yield callback && callback()
      message.success('Тег удален')
    }
  } catch (e) { console.log(e.message) }
}

// <------ Zones ------>

function* postZone({ zone, callback, isPackageUpload }) {
  try {
    const response = yield getResponse({
      method: POST,
      path: '/zone',
      headers: content,
      body: zone,
    })

    if (!isNoMessage(response)) return

    yield put({
      type: POST_ZONE_SUCCEED,
      payload: isPackageUpload && Array.isArray(response) ? response : [response],
    })
    yield callback && callback()
    message.success('Зона создана')
  } catch (e) { console.log(e.message) }
}

function* putZone({ zoneId, zone, callback }) {
  try {
    const updatedZone = yield getResponse({
      method: PUT,
      path: `/zones/${zoneId}`,
      headers: content,
      body: zone,
    })
    if (!isNoMessage(updatedZone)) return

    yield put({ type: PUT_ZONE_SUCCEED, zone: updatedZone })
    message.success('Изменения сохранены')
    yield callback()
  } catch (e) { console.log(e.message) }
}

function* deleteZone({ zoneId }) {
  try {
    const response = yield getResponse({
      method: DELETE,
      path: `/zones/${zoneId}`,
      headers: content,
    })
    if (!isNoMessage(response)) return

    yield put({ type: DELETE_ZONE_SUCCEED, zoneId })
    message.success('Зона удалена')
  } catch (e) { console.log(e.message) }
}

// <------ Tags ------>

function* getOfficeTags({ officeId }) {
  try {
    yield put({ type: SET_OFFICE_TAGS_LOADING })

    const tagsList = yield getResponse({
      method: GET,
      path: `/table_tag?office=${officeId}`,
      headers: auth,
    })

    yield put({ type: GET_OFFICE_TAGS_SUCCEED, tagsList: isNoMessage(tagsList) ? tagsList : [] })
  } catch (e) {
    console.log(e.message)
    yield put({ type: GET_OFFICE_TAGS_FAILED })
  }
}

function* postOfficeTag({ tag, callback }) {
  try {
    const response = yield getResponse({
      method: POST,
      path: '/table_tag',
      body: tag,
      headers: content,
    })
    if (!isNoMessage(response)) return

    yield put({
      type: POST_OFFICE_TAG_SUCCEED,
      payload: response,
    })
    message.success('Сохранено')
    yield callback()
  } catch (e) { console.log(e.message) }
}

function* putOfficeTag({ tagId, tag, callback }) {
  try {
    const updatedTag = yield getResponse({
      method: PUT,
      path: `/table_tags/${tagId}`,
      body: tag,
      headers: content,
    })
    if (!isNoMessage(updatedTag)) return

    yield put({ type: PUT_OFFICE_TAG_SUCCEED, updatedTag })
    message.success('Изменения сохранены')
    yield callback()
  } catch (e) { console.log(e.message) }
}

function* deleteOfficeTag({ tagId }) {
  try {
    const deleteResponse = yield getResponse({
      method: DELETE,
      path: `/table_tags/${tagId}`,
      headers: auth,
    })
    if (deleteResponse.message !== 'OK') return

    yield put({ type: DELETE_OFFICE_TAG_SUCCEED, deletedTag: tagId })
  } catch (e) { console.log(e.message) }
}

function* postTagIconNewDesign({ tagId, icon }) {
  try {
    const postedImage = yield getResponse({
      method: POST,
      path: '/files',
      headers: auth,
      body: icon,
      isFile: true,
    })
    if (!isNoMessage(postedImage)) return

    const tagWithIcon = yield { tag: tagId, icon: postedImage.id }

    const updatedTag = yield getResponse({
      method: PUT,
      path: `/table_tags/${tagId}`,
      headers: content,
      body: tagWithIcon,
    })
    if (!isNoMessage(updatedTag)) return

    yield put({ type: POST_TAG_ICON_SUCCEED_NEW_DESIGN, updatedTag })
  } catch (e) { console.log(e.message) }
}

// <------ Licenses management ------>

function* getLicenses() {
  try {
    const list = yield getResponse({
      method: GET,
      path: '/license?free=true',
      headers: auth,
    })
    yield isNoMessage(list) && put({ type: GET_LICENSES_SUCCEED, list })
  } catch (e) { console.log(e.message) }
}

// <------ Maps management ------>

function* postMap({ floorId, map, sizes, callback }) {
  try {
    yield put({ type: SET_PHOTO_LOADING })
    const photo = yield getResponse({
      method: POST,
      path: '/files',
      headers: auth,
      body: map,
      isFile: true,
    })

    if (!isNoMessage(photo)) return

    const mapObj = yield { floor: floorId, image: photo.id, ...sizes }

    const newFloor = yield getResponse({
      method: POST,
      path: '/floor_map',
      headers: content,
      body: mapObj,
    })

    if (isNoMessage(newFloor)) {
      yield put({ type: POST_MAP_TO_FLOOR_SUCCEED, floorId, newFloor })
      yield callback && callback()
      yield put({ type: SET_STOP_PHOTO_LOADING })
    }
  } catch (e) {
    yield put({ type: SET_STOP_PHOTO_LOADING })
    console.log(e.message)
  }
}

function* postMapNewDesign({ floorId, map, sizes }) {
  try {
    const postedMap = yield getResponse({
      method: POST,
      path: '/files',
      headers: auth,
      body: map,
      isFile: true,
    })
    if (!isNoMessage(postedMap)) return

    const floorWithMap = yield { floor: floorId, image: postedMap.id, ...sizes }

    const updatedFloor = yield getResponse({
      method: POST,
      path: '/floor_map',
      headers: content,
      body: floorWithMap,
    })
    if (!isNoMessage(updatedFloor)) return

    yield put({ type: POST_MAP_TO_FLOOR_SUCCEED_NEW_DESIGN, floorId, updatedFloor })
  } catch (e) {
    yield put({ type: SET_STOP_PHOTO_LOADING })
    console.log(e.message)
  }
}

function* deleteMap({ floor, callback }) {
  try {
    const newFloor = yield getResponse({
      method: DELETE,
      path: '/floor_map',
      headers: content,
      body: floor,
    })

    if (isNoMessage(newFloor)) {
      yield put({ type: DELETE_MAP_SUCCEED, floorId: newFloor.id, newFloor })
      yield callback && callback()
    }
  } catch (e) { console.log(e.message) }
}

function* addMarker({ floorId, marker, callback }) {
  try {
    const room = yield getResponse({
      method: POST,
      path: '/room_map',
      headers: content,
      body: marker,
    })

    if (isNoMessage(room)) {
      yield put({ type: ADD_MARKER_SUCCEED, floorId, room })
      yield callback && callback()
    }
  } catch (e) { console.log(e.message) }
}

function* deleteMarker({ floorId, room }) {
  try {
    console.log(floorId, room)
    const newRoom = yield getResponse({
      method: DELETE,
      path: '/room_map',
      headers: content,
      body: room,
    })

    yield isNoMessage(newRoom) && put({ type: DELETE_MARKER_SUCCEED, floorId, room: newRoom })
  } catch (e) {
    yield put({ type: SET_STOP_PHOTO_LOADING })
    console.log(e.message)
  }
}

function* deleteMarkersOnFloor({ floor, callback }) {
  try {
    const newFloor = yield getResponse({
      method: DELETE,
      path: '/floor_map/clean',
      headers: content,
      body: { floor },
    })

    if (isNoMessage(newFloor)) {
      yield put({ type: DELETE_MARKERS_ON_FLOOR_SUCCEED, floorId: newFloor.id, newFloor })
      yield callback()
    }
  } catch (e) { console.log(e.message) }
}

export default function* pollSagas() {
  // Office
  yield takeLatest(GET_OFFICES, getOfficesList)
  yield takeLatest(GET_OFFICES_NEW_DESIGN, getOfficesListNewDesign)
  yield takeLatest(GET_SEARCH_OFFICES, getOfficesListSearch)
  yield takeLatest(GET_OFFICE, getOffice)
  yield takeLatest(GET_OFFICE_NEW_DESIGN, getOfficeNewDesign)
  yield takeLatest(GET_OFFICE_LOCAL, getOfficeForLocalReducer)
  yield takeLatest(POST_OFFICE, postOffice)
  yield takeLatest(POST_OFFICE_NEW_DESIGN, postOfficeNewDesign)
  yield takeLatest(POST_OFFICE_IMAGES_NEW_DESIGN, postOfficeImagesNewDesign)
  yield takeLatest(PUT_OFFICE, putOffice)
  yield takeLatest(PUT_OFFICE_NEW_DESIGN, putOfficeNewDesign)
  yield takeLatest(UPDATE_ADVANCED_SETTINS_RESPONSE, putAdvancesSettings)
  yield takeLatest(DELETE_OFFICE, deleteOffice)
  yield takeEvery(POST_OFFICE_IMAGE, loadOfficeImage)

  // Floors
  yield takeLatest(GET_FLOORS, getFloors)
  yield takeLatest(POST_FLOOR, postFloor)
  yield takeLatest(PUT_FLOOR, putFloor)
  yield takeLatest(DELETE_FLOOR, deleteFloor)

  // Rooms
  yield takeLatest(GET_ROOMS_LIST, getRoomsList)
  yield takeLatest(REFRESH_ROOMS, refreshRooms)
  yield takeLatest(POST_ROOM, postRoom)
  yield takeLatest(POST_ROOM_NEW_DESIGN, postRoomNewDesign)
  yield takeLatest(PUT_ROOM, putRoom)
  yield takeLatest(PUT_ROOM_NEW_DESIGN, putRoomNewDesign)
  yield takeLatest(DELETE_ROOM, deleteRoom)
  yield takeLatest(DELETE_ROOM_FROM_MAP, deleteRoomFromMap)
  yield takeLatest(DELETE_ROOM_NEW_DESIGN, deleteRoomNewDesign)
  yield takeLatest(DELETE_ROOMS_NEW_DESIGN, deleteRoomsNewDesign)
  yield takeLatest(GET_FILTERED_ROOMS, getRoomsForLocalReducer)
  yield takeEvery(POST_ROOM_IMAGE, loadRoomImage)

  // Tables
  yield takeLatest(ACTIVATE_TABLE, activateTable)
  yield takeLatest(GET_TABLES, getTables)
  yield takeLatest(GET_TABLES_NEW_DESIGN, getTablesNewDesign)
  yield takeLatest(POST_TABLE, postTable)
  yield takeLatest(POST_TABLE_NEW_DESIGN, postTableNewDesign)
  yield takeLatest(PUT_TABLE, putTable)
  yield takeLatest(PUT_TABLE_NEW_DESIGN, putTableNewDesign)
  yield takeLatest(DELETE_TABLE, deleteTable)
  yield takeLatest(DELETE_TABLE_NEW_DESIGN, deleteTableNewDesign)
  yield takeLatest(DELETE_TABLES_NEW_DESIGN, deleteTablesNewDesign)
  yield takeLatest(GET_TABLE_TAGS, getTableTags)
  yield takeEvery(DELETE_TABLE_TAG, deleteTableTag)

  // Zones
  yield takeEvery(POST_ZONE, postZone)
  yield takeEvery(PUT_ZONE, putZone)
  yield takeEvery(DELETE_ZONE, deleteZone)

  // Tags
  yield takeEvery(GET_OFFICE_TAGS, getOfficeTags)
  yield takeEvery(POST_TAG_ICON_NEW_DESIGN, postTagIconNewDesign)
  yield takeEvery(DELETE_OFFICE_TAG, deleteOfficeTag)
  yield takeEvery(POST_OFFICE_TAG, postOfficeTag)
  yield takeEvery(PUT_OFFICE_TAG, putOfficeTag)

  // Room Types
  yield takeEvery(GET_ROOM_TYPES, getRoomTypes)
  yield takeEvery(POST_ROOM_TYPE, postRoomType)
  yield takeEvery(PUT_ROOM_TYPE, putRoomType)
  yield takeEvery(DELETE_ROOM_TYPE, deleteRoomType)
  yield takeLatest(POST_ROOM_TYPE_ICON, postRoomTypeIcon)

  // Licenses
  yield takeEvery(GET_LICENSES, getLicenses)

  // Maps & Markers
  yield takeEvery(POST_MAP_TO_FLOOR, postMap)
  yield takeEvery(POST_MAP_TO_FLOOR_NEW_DESIGN, postMapNewDesign)
  yield takeLatest(ADD_MARKER, addMarker)
  yield takeLatest(DELETE_MARKER, deleteMarker)
  yield takeEvery(DELETE_MAP, deleteMap)
  yield takeEvery(DELETE_MARKERS_ON_FLOOR, deleteMarkersOnFloor)
}
