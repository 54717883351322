import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';

import DatePicker from './DatePicker/DatePicker';
import { STATISTICS_GET } from '../../../../actions/statistics';
import { getCurrentMoment, getMomentMonthsBefore, getUTCfromMoment } from '../utils';
import styles from './styles.module.scss';

const renderOfficesOption = ({ id, title }) => (
  <Select.Option value={id} key={id}>{title}</Select.Option>
);

export default ({ offices }) => {
  const defaultOfficeId = offices[0].id;
  const currentMoment = getCurrentMoment();
  const momentOneMonthBefore = getMomentMonthsBefore(currentMoment);

  const [officeId, setOfficeId] = useState(defaultOfficeId);
  const [startDate, setStartDate] = useState(momentOneMonthBefore);
  const [endDate, setEndDate] = useState(currentMoment);

  const dispatch = useDispatch();

  const payload = {
    officeId,
    startDate: getUTCfromMoment(startDate),
    endDate: getUTCfromMoment(endDate),
  };

  useEffect(() => {
    dispatch({ type: STATISTICS_GET, payload });
  }, [officeId, startDate, endDate]);

  return (
    <div className={styles.root}>
      <div className={styles.selector__wrapper}>
        <Select
          className={styles.selector}
          value={officeId}
          onChange={setOfficeId}
        >
          {!!offices.length && offices.map(renderOfficesOption)}
        </Select>
      </div>
      <DatePicker
        name='start-date'
        value={startDate}
        setValue={setStartDate}
      />
      <span className={styles.date__picker__divider} />
      <DatePicker
        name='end-date'
        value={endDate}
        setValue={setEndDate}
      />
    </div>
  );
};
