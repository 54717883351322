export default {
  AVAILABLE_FOR_BOOKING: {
    title: 'Рабочие места, доступные для бронирования',
  },
  REGISTERED_USERS: {
    title: 'Количество зарегистрированных пользователей',
  },
  TABLES_ONCE_BOOKING: {
    title: 'Доля рабочих мест, которые были хотя бы раз забронированы',
  },
  TABLES_ALL_BOOKING: {
    title: 'Доля зарегистрированных рабочих мест',
  },
  ALL_BOOKINGS: {
    title: 'Количество бронирований',
  },
  CONFIRMED_BOOKINGS: {
    title: 'Доля подтвержденных бронирований',
  },
  AVERAGE_DISPOSAL: {
    title: 'Средний процент утилизации по всем рабочим местам',
  },
};
