import {
  GET_OFFICE_SUCCEED_NEW_DESIGN,
  GET_OFFICES_SUCCEED_NEW_DESIGN,
  OFFICES_LOADING_NEW_DESIGN,
  GET_OFFICES_FAILED_NEW_DESIGN,
  OFFICE_LOADING_NEW_DESIGN,
  SET_TABLES_LOADING_NEW_DESIGN,
  GET_TABLES_SUCCEED_NEW_DESIGN,
  GET_OFFICE_FAILED_NEW_DESIGN,
  PUT_OFFICE_SUCCEED_NEW_DESIGN,
  POST_OFFICE_SUCCEED_NEW_DESIGN,
  CLEAR_OFFICE_NEW_DESIGN,
  DELETE_OFFICE_SUCCEED,
  DELETE_FLOOR_SUCCEED_NEW_DESIGN,
  POST_MAP_TO_FLOOR_SUCCEED_NEW_DESIGN,
  POST_FLOOR_NEW_DESIGN_SUCCEED,
  PUT_FLOOR_NEW_DESIGN_SUCCEED,
  SET_OFFICE_TAGS_LOADING,
  GET_OFFICE_TAGS_SUCCEED,
  GET_OFFICE_TAGS_FAILED,
  POST_TAG_ICON_SUCCEED_NEW_DESIGN,
  POST_OFFICE_TAG_SUCCEED,
  DELETE_OFFICE_TAG_SUCCEED,
  PUT_OFFICE_TAG_SUCCEED,
  POST_ZONE_SUCCEED,
  PUT_ZONE_SUCCEED,
  DELETE_ZONE_SUCCEED,
  POST_OFFICE_IMAGES_SUCCEED_NEW_DESIGN,
  POST_ROOM_TYPE_SUCCEED,
  PUT_ROOM_TYPE_SUCCEED,
  DELETE_ROOM_TYPE_SUCCEED,
  GET_ROOM_TYPES_SUCCEED,
  GET_ROOM_TYPES_FAILED,
  SET_ROOM_TYPES_LOADING,
  POST_ROOM_TYPE_ICON_SUCCEED,
  DELETE_MARKER_SUCCEED,
  ADD_MARKER_SUCCEED,
  DELETE_ROOM_FROM_MAP_SUCCEED,
  PUT_ROOM_NEW_DESIGN_SUCCEED,
  REFRESH_ROOMS_SUCCESS,
  SET_REFRESH_ROOMS_LOADING,
  UPDATE_ADVANCED_SETTINS_SUCCESS,
  UPDATE_ADVANCED_SETTINS_FAILED,
  DELETE_MARKERS_ON_FLOOR_SUCCEED,
} from '../actions/offices'

const defaultState = {
  loading: false,
  office: {
    loading: false,
    tables: [],
    rooms: [],
    advancedSettings: {
      timeBeforeBook: 10,
      timeAfterBook: 30,
    },
  },
  floors: {},
  list: [],
  count: 0,
  refreshRoomsLoading: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_OFFICES_SUCCEED_NEW_DESIGN: {
      const newOffices = (state.list && action.page !== 1)
        ? [...state.list, ...action.list]
        : action.list

      return { ...state, list: newOffices, count: action.count, loading: false }
    }

    case OFFICES_LOADING_NEW_DESIGN: {
      return { ...state, loading: true }
    }

    case GET_OFFICES_FAILED_NEW_DESIGN: {
      return { ...state, loading: false, list: [] }
    }

    case GET_OFFICE_SUCCEED_NEW_DESIGN: {
      return { ...state, office: { ...state.office, ...action.office, loading: false } }
    }

    case GET_OFFICE_FAILED_NEW_DESIGN: {
      return { ...state, office: { ...defaultState.office, loading: false } }
    }

    case OFFICE_LOADING_NEW_DESIGN: {
      const newOffice = { ...state.office, loading: true }
      return { ...state, office: newOffice }
    }

    case DELETE_OFFICE_SUCCEED: {
      const newOffices = state.list?.filter(o => o.id !== action.officeId)
      return { ...state, list: newOffices }
    }

    case CLEAR_OFFICE_NEW_DESIGN: return { ...state.office, office: defaultState.office }

    case PUT_OFFICE_SUCCEED_NEW_DESIGN:
      return {
        ...state,
        office: { ...state.office, ...action.updatedOffice, floors: [...state.office?.floors] },
      }

    case SET_OFFICE_TAGS_LOADING:
      return {
        ...state,
        office: { ...state.office, tags: { loading: true } },
      }

    case GET_OFFICE_TAGS_SUCCEED:
      return {
        ...state,
        office: {
          ...state.office,
          tags: { loading: false, list: action.tagsList },
        },
      }

    case GET_OFFICE_TAGS_FAILED:
      return {
        ...state,
        office: { ...state.office, tags: { loading: false, list: [] } },
      }

    case POST_OFFICE_TAG_SUCCEED: {
      const { payload = [] } = action
      const prevTags = state.office.tags ? state.office.tags.list : [];

      const newTagsList = prevTags.length
        ? [...payload, ...prevTags]
        : payload

      return {
        ...state,
        office: {
          ...state.office,
          tags: {
            ...state.office.tags,
            list: newTagsList,
          },
        },
      }
    }

    case PUT_OFFICE_TAG_SUCCEED: {
      const { updatedTag } = action
      const newTagsList = state.office.tags.list
      const targetIndex = newTagsList.findIndex(tag => tag.id === updatedTag.id)
      newTagsList[targetIndex] = updatedTag

      return {
        ...state,
        office: {
          ...state.office,
          tags: {
            ...state.office.tags,
            list: newTagsList,
          },
        },
      }
    }

    case DELETE_OFFICE_TAG_SUCCEED: {
      const { deletedTag } = action
      const newTagsList = state.office.tags?.list.filter(tag => tag.id !== deletedTag)
      return {
        ...state,
        office: {
          ...state.office,
          tags: {
            ...state.office.tags,
            list: newTagsList,
          },
        },
      }
    }

    case POST_TAG_ICON_SUCCEED_NEW_DESIGN: {
      const { updatedTag } = action
      const newTagsList = state.office.tags.list
      const targetIndex = newTagsList.findIndex(tag => tag.id === updatedTag.id)
      newTagsList[targetIndex] = updatedTag

      return {
        ...state,
        office: {
          ...state.office,
          tags: {
            ...state.office.tags,
            list: newTagsList,
          },
        },
      }
    }

    case POST_OFFICE_SUCCEED_NEW_DESIGN:
      return { ...state, office: action.newOffice }

    case SET_TABLES_LOADING_NEW_DESIGN: {
      const newTables = [...state.office.tables, {
        loading: true,
        roomId: action.roomId,
        list: [],
      }]
      const newOffice = { ...state.office, tables: newTables }
      return { ...state, office: newOffice }
    }

    case GET_TABLES_SUCCEED_NEW_DESIGN: {
      const { roomId, list } = action
      const targetIndex = state.office.tables.findIndex(tableItem => tableItem.roomId === roomId)

      const newTables = [...state.office.tables]

      newTables[targetIndex] = { loading: false, roomId, list }

      const newOffice = { ...state.office, tables: newTables }
      return { ...state, office: newOffice }
    }

    case DELETE_FLOOR_SUCCEED_NEW_DESIGN: {
      const { floorId } = action
      const newFloorsList = state.office.floors.filter(f => f.id !== floorId)
      const rooms = state.office.rooms || [];
      const newRoomsList = rooms.filter(r => r.floor.id !== floorId)
      return {
        ...state,
        office: {
          ...state.office,
          floors_number: state.office.floors_number - 1,
          floors: newFloorsList,
          rooms: newRoomsList,
        },
      }
    }

    case POST_FLOOR_NEW_DESIGN_SUCCEED: {
      const { payload: newFloors } = action
      const prevFloors = [...state.office.floors]

      const newFloorsList = [...newFloors, ...prevFloors]
      return {
        ...state,
        office: { ...state.office, floors: newFloorsList },
      }
    }

    case UPDATE_ADVANCED_SETTINS_SUCCESS: {
      const { newSettings } = action

      return {
        ...state,
        office: {
          ...state.office,
          tags: { ...state.office.tags, loading: false },
          advancedSettings: newSettings,
        },
      }
    }

    case UPDATE_ADVANCED_SETTINS_FAILED: {
      return {
        ...state,
        office: {
          ...state.office,
          tags: { ...state.office.tags, loading: false },
        },
      }
    }

    case PUT_FLOOR_NEW_DESIGN_SUCCEED: {
      const { office } = state
      const { newFloor } = action

      const floorIndex = office.floors.findIndex(({ id }) => id === newFloor.id)
      const newFloorsList = [...office.floors]
      newFloorsList[floorIndex] = { ...newFloorsList[floorIndex], ...newFloor }

      return {
        ...state,
        office: { ...office, floors: newFloorsList },
      }
    }

    case POST_MAP_TO_FLOOR_SUCCEED_NEW_DESIGN: {
      const { floorId, updatedFloor } = action

      const floorIndex = state.office.floors.findIndex(floor => floor.id === floorId)
      const newFloors = state.office.floors
      newFloors[floorIndex] = updatedFloor

      return {
        ...state,
        office: { ...state.office, floors: [...newFloors] },
      }
    }

    case POST_ZONE_SUCCEED: {
      const { payload = [] } = action
      const previousZones = state.office.zones

      const newZonesList = previousZones.length
        ? [...payload, ...previousZones]
        : payload

      return {
        ...state,
        office: { ...state.office, zones: newZonesList },
      }
    }

    case PUT_ZONE_SUCCEED: {
      const { zone } = action
      const zoneIndex = state.office.zones.findIndex(z => z.id === zone.id)
      const newZonesList = [...state.office.zones]
      newZonesList[zoneIndex] = zone

      return {
        ...state,
        office: { ...state.office, zones: newZonesList },
      }
    }

    case DELETE_ZONE_SUCCEED: {
      const { zoneId } = action
      const newZonesList = state.office.zones.filter(z => z.id !== zoneId)

      return {
        ...state,
        office: { ...state.office, zones: newZonesList },
      }
    }

    case POST_OFFICE_IMAGES_SUCCEED_NEW_DESIGN: {
      const { postedImages: newList } = action
      const { images: prevList } = state.office
      const newImagesList = prevList?.length ? [...newList, ...prevList] : newList

      return {
        ...state,
        office: { ...state.office, images: newImagesList },
      }
    }

    case PUT_ROOM_NEW_DESIGN_SUCCEED: {
      const { room } = action

      const newRooms = [...state.office.rooms]
      const targetRoomIndex = newRooms.findIndex(r => r.id === room.id)

      newRooms[targetRoomIndex] = room

      return {
        ...state,
        office: {
          ...state.office,
          rooms: newRooms,
        },
      }
    }

    // Room Types

    case SET_ROOM_TYPES_LOADING:
      return { ...state, roomTypes: { loading: true } }

    case GET_ROOM_TYPES_FAILED:
    case GET_ROOM_TYPES_SUCCEED:
      return { ...state, roomTypes: { loading: false, list: action.list } }

    case POST_ROOM_TYPE_SUCCEED: {
      const { payload } = action
      const previousTypes = state.roomTypes ? state.roomTypes.list : [];

      const newRoomTypesList = previousTypes.length
        ? [...payload, ...previousTypes]
        : payload

      return {
        ...state,
        roomTypes: { ...state.roomTypes, list: newRoomTypesList },
      }
    }

    case POST_ROOM_TYPE_ICON_SUCCEED:
    case PUT_ROOM_TYPE_SUCCEED: {
      const { roomType } = action

      const targetIndex = state.roomTypes.list.findIndex(t => t.id === roomType.id)
      const newRoomTypesList = [...state.roomTypes.list]
      newRoomTypesList[targetIndex] = roomType

      return {
        ...state,
        roomTypes: { ...state.roomTypes, list: newRoomTypesList },
      }
    }

    case DELETE_ROOM_TYPE_SUCCEED: {
      const { roomTypeId } = action
      const newRoomTypesList = state.roomTypes.list.filter(t => t.id !== roomTypeId)

      return {
        ...state,
        roomTypes: { ...state.office.roomTypes, list: newRoomTypesList },
      }
    }

    case DELETE_MARKER_SUCCEED:
    case ADD_MARKER_SUCCEED: {
      const { room } = action

      const newRooms = [...state.office.rooms]
      const roomIndex = newRooms.findIndex(r => r.id === room.id)

      newRooms[roomIndex] = room

      return {
        ...state,
        office: {
          ...state.office,
          rooms: newRooms,
        },
      }
    }

    case DELETE_MARKERS_ON_FLOOR_SUCCEED: {
      const { floorId } = action
      const newRooms = state.office.rooms.map(room => room.floor.id === floorId
        ? { ...room, marker: null }
        : room)
      return {
        ...state,
        office: {
          ...state.office,
          rooms: newRooms,
        },
      }
    }

    case DELETE_ROOM_FROM_MAP_SUCCEED: {
      const { roomId } = action

      const newRoomsList = state.office.rooms
      const targetRoomIndex = newRoomsList.findIndex(r => r.id === roomId)
      const newRoom = newRoomsList[targetRoomIndex]

      newRoom.deleted = true
      newRoomsList[targetRoomIndex] = newRoom

      return {
        ...state,
        office: {
          ...state.office,
          rooms: newRoomsList,
        },
      }
    }

    case SET_REFRESH_ROOMS_LOADING: {
      return {
        ...state,
        refreshRoomsLoading: action.boolValue,
      }
    }

    case REFRESH_ROOMS_SUCCESS: {
      return {
        ...state,
        office: {
          ...state.office,
          rooms: action.rooms,
        },
      }
    }

    default:
      return state
  }
}
