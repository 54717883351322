import { call, put, takeEvery } from 'redux-saga/effects';

import {
  STATISTICS_GET,
  STATISTICS_SET_LOADING,
  STATISTICS_GET_SUCCEED,
  STATISTICS_GET_FAILED,
} from '../actions/statistics';

// HARDCODE facts
const facts = [
  {
    id: 1,
    type: 'AVAILABLE_FOR_BOOKING',
    value: 12287,
    badgeValue: '99%',
  },
  {
    id: 2,
    type: 'REGISTERED_USERS',
    value: 523,
  },
  {
    id: 3,
    type: 'TABLES_ONCE_BOOKING',
    value: '32%',
  },
  {
    id: 4,
    type: 'TABLES_ALL_BOOKING',
    value: '85%',
  },
  {
    id: 5,
    type: 'ALL_BOOKINGS',
    value: 2500,
  },
  {
    id: 6,
    type: 'CONFIRMED_BOOKINGS',
    value: '80%',
  },
  {
    id: 7,
    type: 'AVERAGE_DISPOSAL',
    value: '60%',
  },
  // Копия
  {
    id: 8,
    type: 'CONFIRMED_BOOKINGS',
    value: '90%',
  },
];

// fake api call
const fetchStatistics = (options, delay = 2000) => new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve(facts);
  }, delay);
});

function* getStatistics(action) {
  yield put({ type: STATISTICS_SET_LOADING, payload: true });

  try {
    const response = yield call(fetchStatistics, action.payload);

    yield put({ type: STATISTICS_GET_SUCCEED, payload: response });
  } catch (error) {
    yield put({ type: STATISTICS_GET_FAILED });
    // eslint-disable-next-line no-console
    console.log(error.message);
  } finally {
    yield put({ type: STATISTICS_SET_LOADING, payload: false });
  }
}

export default function* statisticsSagas() {
  yield takeEvery(STATISTICS_GET, getStatistics);
}
