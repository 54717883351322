import {
  STATISTICS_SET_LOADING,
  STATISTICS_GET_SUCCEED,
  STATISTICS_GET_FAILED,
} from '../actions/statistics';

const initialState = {
  isLoading: false,
  facts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STATISTICS_SET_LOADING: {
      return { ...state, isLoading: action.payload }
    }
    case STATISTICS_GET_SUCCEED: {
      return { ...state, facts: action.payload }
    }
    case STATISTICS_GET_FAILED: {
      return { ...state, facts: [] }
    }
    default: {
      return state;
    }
  }
};
