import React from 'react';

import MainCard from '../MainCard'
import Selectors from './Selectors/Selectors';
import Facts from './Facts/Facts';

export default () => (
  <MainCard
    title='cтатистика бронирования рабочих мест'
    icon='icon__graphic'
    innerContainerStyle={{ paddingLeft: '2px' }}
  >
    <Selectors />
    <Facts />
  </MainCard>
);
